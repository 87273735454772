<template>
  <div class="w-full pt-2 pb-4 flex" style="justify-content: space-between">
    <div class="flex w-full mt-4">
      <img class="mr-3" src="../../../../../../assets/images/hand-money-blue.svg" alt="">
      <p class="text-xl text-blue-800 font-bold">Crear Recepción</p>
    </div>
    <Button label="Atrás" icon="pi pi-arrow-left" class="p-button-raised" @click="backPage"/>
  </div>
  <Card>
    <template #content>
      <div style="width: 100%">
        <div class="gap-1 grid text-xs grid-cols-2 md:grid-cols-4 lg:grid-cols-4 p-1">
          <div>
            <p class="text-xs">
              <strong>Código Cliente/Entidad: </strong>
            </p>
          </div>
          <div>
            <InputText class="p-inputtext-xs inputsHeader" v-model="encabezado.CardCode" disabled/>
          </div>
          <div>
            <p class="text-xs xl:pl-10 md:pl-10 lg:pl-10">
              <strong>Fecha de Contabilización: </strong>
            </p>
          </div>
          <div>
            <input style="padding: 0.30rem;" class="border rounded-md p-inputtext-xs inputsEditableHeader w-72"
                   v-model="encabezado.TaxDate" type="date">
          </div>
        </div>
        <div class="gap-1 grid text-xs grid-cols-2 md:grid-cols-4 lg:grid-cols-4 p-1">
          <div>
            <p class="text-xs">
              <strong>Nombre Cliente/Entidad: </strong>
            </p>
          </div>
          <div>
            <InputText class="p-inputtext-xs inputsHeader" v-model="encabezado.CardName" disabled/>
          </div>
          <div>
            <p class="text-xs xl:pl-10 md:pl-10 lg:pl-10">
              <strong>Fecha de Vencimiento:</strong>
            </p>
          </div>
          <div>
            <input style="padding: 0.30rem;" class="border rounded-md p-inputtext-xs inputsEditableHeader w-72"
                   v-model="encabezado.ExpDate" type="date">
          </div>
        </div>
        <div class="gap-1 grid text-xs grid-cols-2 md:grid-cols-4 lg:grid-cols-4 p-1">
          <div>
            <p class="text-xs">
              <strong>N. Factura Proveedor: </strong>
            </p>
          </div>
          <div>
            <input ref="numFact" class="p-1.5 rounded border p-inputtext-xs inputsEditableHeader"
                   v-model="encabezado.numFactura"/>
          </div>
          <div>
            <p class="text-xs xl:pl-10 md:pl-10 lg:pl-10">
              <strong>Fecha de Documento: </strong>
            </p>
          </div>
          <div>
            <input style="padding: 0.30rem;" class="border rounded-md p-inputtext-xs inputsEditableHeader w-72"
                   v-model="encabezado.DocDate" type="date">
          </div>
        </div>
        <div class="flex text-xs p-1">
          <div>
            <p class="text-xs">
              <strong>Pedidos: </strong>
            </p>
          </div>
          <div class="pl-2"> {{ encabezado.pedidos ? encabezado.pedidos.join(',') : '' }}</div>
        </div>
        <div class="flex text-xs p-1">
          <div>
            <p class="text-xs">
              <strong>Cantidad de lineas: </strong>
            </p>
          </div>
          <div class="pl-2">
            {{ detailTemp.length  }}
          </div>
        </div>
      </div>
      <div style="width: 100%" class="p-2 mt-10">
        <div>
          <DataTable
            :value="detailTemp"
            class="p-datatable-sm text-xs"
            :paginator="true"
            :rows="50"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[50,100,200]"
            currentPageReportTemplate="{last} Pag. de {totalRecords}"
            showGridlines
            dataKey="id"
            responsiveLayout="scroll"
            v-model:filters="filters"
            :globalFilterFields="['nCompleto']"
            filterDisplay="row"
            :rowClass="rowClass"
          >
            <template #empty>
              No hay datos para mostrar
            </template>
            <template #header>
              <div class="flex justify-end align-items-center">
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText class="p-inputtext-xxs" placeholder="Busqueda..." @blur="searchCodeBars(CodeBars)" v-model="CodeBars"/>
                </span>
              </div>
            </template>
            <Column
              field="nCompleto"
              header="N° Artículo - Medicamento"
              headerClass="lg:text-xs"
              class="my-1 py-1 shadow-xs"
              filterField="nCompleto"
              :showFilterMatchModes="false"
              style="min-width: 20rem;"
            >
              <!--              <template #filter="{filterModel}">-->
              <!--                <div class="mb-3 font-bold">Seleccionar medicamentos</div>-->
              <!--                <MultiSelect v-model="filterModel.value" :options="medicamentosList" optionLabel="Dscription"-->
              <!--                             optionValue="ItemCode" placeholder="Selección de medicamentos.." class="p-column-filter">-->
              <!--                  <template #option="slotProps">-->
              <!--                    <div class="p-multiselect-representative-option">-->
              <!--                      <span class="text-xs">{{ slotProps.option.ItemCode + ' - ' + slotProps.option.Dscription }}</span>-->
              <!--                    </div>-->
              <!--                  </template>-->
              <!--                </MultiSelect>-->
              <!--              </template>-->
              <template #filter="{filterModel,filterCallback}">
                <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
                           class="p-inputtext-xxs" v-tooltip.top.focus="'Presione enter para filtrar'"
                           :placeholder="`Buscar medicamentos... `"/>
              </template>
              <template #body="slotProps">
                <div class="h-full">
                  <div class="flex justify-between items-center my-1 pr-2 pl-2">
                    <div>
                      <span class="font-bold mr-2 ">Medicamento: </span><br>{{
                        slotProps.data.U_PHR_ItemCodSolicitado + ' - ' + slotProps.data.Dscription
                      }}{{ '    ' }}
                    </div>
                    <div class="flex gap-2">
                      <Badge v-if="slotProps.data.ReqMarca" :value="`Req. marca` "
                             class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                      <Badge v-if="slotProps.data.Controlado" value="Controlado"
                             class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="slotProps.data.Regulado" value="Regulado"
                             class=" bg-blue-300 text-blue text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="slotProps.data.cadenaFrio" value="Cadena Frio"
                             class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                    </div>
                  </div>
                  <div class="text-xs p-2">
                    <span class="font-bold mr-2">Cod. Barras: </span><br>{{ slotProps.data.CodeBars }}
                  </div>
                  <div class="text-xs p-2" v-if="!slotProps.data.medAdicional && slotProps.data.sustitutos.length">
                    <span class="font-bold mr-2">Sustitutos: </span><br>
                    <Dropdown
                      v-model="slotProps.data.ItemCode"
                      :options="slotProps.data.sustitutos"
                      @click="$h.xxsInput"
                      class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xxs"
                      panelClass="text-xxs"
                      optionLabel="nomCompleto"
                      :showClear="true"
                      optionValue="ItemCode"
                      @change="MxSustituto(slotProps)"
                      :filter="true"
                      placeholder="Seleccione un sustituto..."/>
                  </div>
                  <div class="text-xs p-2 flex gap-2" v-if="slotProps.data.cadenaFrio">
                    <div>
                      <span class="font-bold mr-2">Temperatura: </span><br>
                      <InputNumber @blur="validateTemp($event,slotProps)" inputId="integeronly" suffix="℃"
                                   v-model="slotProps.data.temperatura" inputClass="p-inputtext-xxs w-12"/>
                    </div>
                      <div class="flex items-center"
                           v-if="slotProps.data.temperatura !== null && slotProps.data.temperatura > slotProps.data.rangoTemperatura || slotProps.data.temperatura !== null && slotProps.data.temperatura < 1">
                        <Badge value="Fuera de rango" severity="danger" class="text-xxs h-4"></Badge>
                      </div>
                  </div>
                </div>
                <div class="text-xs pl-2 pr-2" v-if="slotProps.data.cadenaFrio && !slotProps.data.temperatura">
                  <small class="color-red" >Por favor ingrese una temperatura para este medicamento.</small>
                </div>
              </template>
            </Column>
            <Column field="Quantity" header="Cantidad, Precio & Total" headerStyle="justify-content: left"
                    bodyStyle="text-align: left" style="min-width:13rem">
              <template #body="{data}">
                <div class="h-full">
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Num. pedido: </span>{{ data.DocNum }}
                  </p>
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Cant. solicitada: </span>{{ $h.formatNumber(data.U_PHR_Quantity) }}
                  </p>
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Precio unitario: </span>{{
                      $h.formatCurrency(data.U_PHR_UnitPriceSolicitado)
                    }}
                  </p>
                  <p class="text-xs p-1  grid grid-cols-2">
                    <span class="font-bold mr-2">Precio total: </span>{{ $h.formatCurrency(data.LineTotal) }}
                  </p>
                  <p class="text-xs p-1  grid grid-cols-2">
                    <span class="font-bold mr-2">Precio total confirmado: </span>{{
                      $h.formatCurrency(data.Quantity * data.Price)
                    }}
                  </p>
                </div>
              </template>
            </Column>
            <Column field="ItemName" header="Confirmación" headerStyle="justify-content: left;"
                    bodyStyle="text-align: left" style="min-width:19rem">
              <template #body="{data}">
                <div class="h-full">
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Cantidad Factura: </span>
                    <InputNumber inputId="integeronly" :min="0" inputClass="p-inputtext-xxs w-12"
                                 v-model="data.Quantity"/>
                  </p>
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Cantidad Recibida: </span>
                    <InputNumber inputId="integeronly" :min="0" inputClass="p-inputtext-xxs w-12"
                                 v-model="data.receivedQuantity"/>
                  </p>
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Cantidad defectuosa: </span>
                    <InputNumber inputId="integeronly" :min="0" :max="data.receivedQuantity"
                                 inputClass="p-inputtext-xxs w-12" v-model="data.cantidadDefectuosa"/>
                  </p>
                  <p class="text-xs p-1 grid grid-cols-2">
                    <span class="font-bold mr-2">Confirmar Precio: </span>
                    <InputNumber inputId="integeronly" :min="1" inputClass="p-inputtext-xxs w-12" v-model="data.Price"/>
                  </p>
                </div>
              </template>
            </Column>
            <Column field="" header="Acciones" headerStyle="justify-content: left;"
                    bodyStyle="text-align: center" style="min-width:8rem">
              <template #body="slotProps">
                <Button v-tooltip.top="'Asignar Lotes'"
                        class="p-button-text p-button-success p-1.5" @click="openModal(slotProps)">
                  <PackageIcon class="w-9/12"/>
                </Button>
                <Button v-tooltip.top="'Actualizar  Distribución'" v-if="slotProps.data.lineaConfirmada"
                        class="p-button-text p-button-success p-1.5" @click="openDistribuciones(slotProps)">
                  <menuIcon class="w-9/12"/>
                </Button>
                <Button v-if="slotProps.data.medAdicional" icon="pi pi-trash" v-tooltip.top="'Quitar linea'"
                        iconClass="text-xl"
                        class="p-button-text p-button-danger" @click="deleteLine(slotProps)">
                </Button>
                <!--                <Button icon="pi pi-check" v-tooltip.top="'Confirmar Linea'" v-if="!slotProps.data.lineaConfirmada"-->
                <!--                        class="p-button-outlined p-button-success" @click="openModal(slotProps)"/>-->
                <!--                <Button icon="pi pi-pencil" v-tooltip.top="'Editar Linea'" v-else-->
                <!--                        class="p-button-outlined p-button-warning" @click="editLine(slotProps)"/>-->
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </template>
  </Card>
  <Card>
    <template #content>
      <div class="pt-2 flex justify-end">
        <Button v-if="$can('pharmasan.compras.logistica.recepcion-pedidos.listado-pedidos.copiar-a-recepcion')"
                label="Crear Recepción" icon="pi pi-save"
                class="p-button-raised p-button-success floating-button  p-button-rounded" @click="onSubmit"/>
      </div>
      <div class="w-full p-2 grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-8 lg:col-span-2">
          <div class="flex flex-col">
            <p class="text-xs mb-2">
              <strong>Comentarios: </strong>
            </p>
            <Textarea
              class="p-inputtext-xs"
              disabled
              :autoResize="true"
              v-model="Comments"
              rows="5"
              cols="40"
            />
          </div>
          <div class="flex flex-col">
            <p class="text-xs mb-2">
              <strong>Entrada de diario: </strong>
            </p>
            <Textarea
              class="p-inputtext-xs"
              disabled
              :autoResize="true"
              v-model="JournalMemo"
              rows="5"
              cols="40"
            />
          </div>
        </div>
        <div class="text-xs lg:col-span-1">
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total Pedido:</span>
            <span class="">{{ $h.formatCurrency(totalPedido) }}</span>
          </div>
          <div class="flex justify-between my-3">
            <span class="text-gray-600">Total IVA:</span>
            <span class="">{{ $h.formatCurrency(totalSumIva) }}</span>
          </div>
          <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
            <span class="font-bold">Total Confirmado:</span>
            <span class="">{{ $h.formatCurrency(totalSelec + totalSumIva) }}</span>
          </div>
        </div>
      </div>
    </template>
  </Card>
  <div>
    <modalLotes ref="ModalLotes"/>
  </div>
  <div>
    <modalCodeBars ref="openModalCodBars" @refrescar="updateCodeBar"/>
  </div>
  <div>
    <modalDistribucion ref="openModalDistribuciones" @distriBodega="onSetDistribucion"/>
  </div>
</template>
<script>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import recepcionPedidos from '@/apps/pharmasan/compras/logistica/store/recepcionPedidos'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import { FilterMatchMode } from 'primevue/api'
import { useStore } from 'vuex'
// import _ from 'lodash'

export default {
  name: 'createRecepcion',
  components: {
    modalLotes: defineAsyncComponent(() => import('./components/modalAsignarLotes.vue')),
    modalCodeBars: defineAsyncComponent(() => import('./components/asignarCodigoBarras.vue')),
    modalDistribucion: defineAsyncComponent(() => import('./components/modalDistribucion.vue'))
  },
  setup () {
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const router = useRouter()
    const store = useStore()
    const openModalDistribuciones = ref()
    const JournalMemo = ref('')
    const Comments = ref('')
    const CodeBars = ref(null)
    const detailTemp = ref([])
    const responseError = ref('')
    const filters = ref({
      nCompleto: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    })
    const ModalLotes = ref()
    const openModalCodBars = ref()
    const dataRecepcion = ref([])
    const encabezado = ref({})
    const numFact = ref()
    const storeRecepcionPedidos = computed(() => recepcionPedidos.getters._medicamentos)
    const storeEncabezado = computed(() => recepcionPedidos.getters._encabezado)
    const storeDetailsLotes = computed(() => recepcionPedidos.getters._DocumentLines)
    const storeRecepcionPedidoBodegas = computed(() => recepcionPedidos.getters._bodegas)
    const totalPedido = computed(() => {
      if (!storeRecepcionPedidos.value.data) return 0
      return storeRecepcionPedidos.value.data.reduce((total, current) => total + (current.Quantity * current.Price), 0)
    })
    const totalSelec = computed(() => {
      if (!storeRecepcionPedidos.value.data) return 0
      return storeRecepcionPedidos.value.data.filter(x => x.lineaConfirmada).reduce((acc, val) => acc + (val.Price * val.Quantity), 0)
    })
    const totalSumIva = computed(() => {
      if (!storeRecepcionPedidos.value.data) return 0
      return storeRecepcionPedidos.value.data.filter(x => x.lineaConfirmada).reduce((acc, val) => acc + ((val.Price * val.Quantity) * (val.IVA / 100)), 0)
    })
    const medicamentosList = computed(() => {
      return storeRecepcionPedidos.value.data.map((x) => {
        return {
          ItemCode: x.ItemCode,
          Dscription: x.Dscription
        }
      })
    })
    const searchCodeBars = (codebars) => {
      const params = {
        pedidos: [...new Set(dataRecepcion.value.filter(x => !x.medAdicional).map(p => p.DocNum))],
        codigoBarras: codebars || null,
        cardCode: encabezado.value.CardCode
      }
      detailTemp.value = dataRecepcion.value
      if ([null, ''].includes(codebars)) {
        detailTemp.value = dataRecepcion.value
        return
      }
      /* inicialmente, busca en front si algún medicamento o sustituto posee el código de barras a buscar */
      const findList = detailTemp.value.filter((x) => x.CodeBars.toString() === codebars || x.sustitutos.some((a) => a.CodeBars && a.CodeBars.toString() === codebars))
      if (findList.length) {
        const medInList = detailTemp.value.filter((x) => x.CodeBars.toString() === codebars)
        if (medInList.length) {
          CodeBars.value = null
          detailTemp.value = medInList
          return
        }
        const codeBarsInSustitutos = findList.filter(x => x.sustitutos.some((a) => a.CodeBars && a.CodeBars.toString() === codebars))
        if (codeBarsInSustitutos.length) {
          const detalle = codeBarsInSustitutos.map((x) => {
            return `<span> * Medicamento: ${x.U_PHR_ItemCodSolicitado} - ${x.Dscription} Pedido: ${x.DocNum}</span><br>`
          })
          Swal.fire({
            icon: 'info',
            title: '<span class="text-xl"> Medicamento encontrado en sustitutos </span>',
            width: 700,
            padding: '1rem 0rem 1rem 0rem',
            html: `
            <div class="text-base">
              <span>Los siguientes medicamentos tienen el código de barras <strong>${codebars}</strong> dentro de sus sustitutos:</span>
              <div>
                 ${detalle}
              </div>
              <div>
                <span>¿Desea seleccionar el medicamento como sustituto o agregarlo como uno nuevo? </span>
              </div>
            </div>
              `,
            showDenyButton: true,
            showCancelButton: true,
            focusCancel: true,
            confirmButtonText: 'Seleccionar sustituto',
            denyButtonText: 'Agregar medicamento',
            denyButtonColor: '#689F38',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              /* recorre la lista filtrada para en el caso de que el código de barras encontrado fue en un sustituto seleccionarlo automáticamente */
              for (const item of findList) {
                const findSustituto = item.sustitutos.find(x => x.CodeBars && x.CodeBars.toString() === codebars)
                if (findSustituto !== undefined) {
                  item.ItemCode = findSustituto.ItemCode
                }
              }
              detailTemp.value = findList
            }
            if (result.isDenied) {
              const maximo = dataRecepcion.value.reduce((max, obj) => parseInt(obj.LineNum) > max ? parseInt(obj.LineNum) : max, dataRecepcion.value[0].LineNum) + 1
              const infoMdToAdd = codeBarsInSustitutos[0].sustitutos.find(a => a.CodeBars && a.CodeBars.toString() === codebars)
              const dataSave = {
                LineNum: maximo,
                DocNum: null,
                DocEntry: null,
                BaseLine: null,
                ItemCode: infoMdToAdd.ItemCode,
                nCompleto: `${infoMdToAdd.ItemCode} - ${infoMdToAdd.Dscription}`,
                U_PHR_ItemCodSolicitado: infoMdToAdd.ItemCode,
                Dscription: infoMdToAdd.Dscription,
                U_PHR_AgrupCom: infoMdToAdd.U_PHR_AgrupCom,
                U_PHR_Conversion: infoMdToAdd.U_PHR_Conversion,
                WhsCode: 'BOD0002',
                U_PHR_WarehouseCodeOri: null,
                Quantity: 0,
                Price: 0,
                LineTotal: 0,
                LineStatus: 'O',
                IVA: infoMdToAdd.taxRate,
                TaxCode: infoMdToAdd.TaxCodeAP,
                Regulado: infoMdToAdd.Regulado,
                Controlado: infoMdToAdd.Controlado,
                CodeBars: infoMdToAdd.CodeBars,
                U_PHR_Quantity: 0,
                U_PHR_UnitPriceSolicitado: 0,
                onQuantity: 0,
                lineaConfirmada: false,
                medAdicional: true,
                receivedQuantity: 0,
                cantidadDefectuosa: 0,
                temperatura: 0,
                sustitutos: []
              }
              recepcionPedidos.commit('onAddMx', dataSave)
            }
          })
          CodeBars.value = null
          return
        }
        return
      }
      _RecepcionPedidosService.value.getLinesMx(params).then(({ data }) => {
        if (data.data.length && data.medOtroPedido) {
          Swal.fire({
            icon: 'info',
            title: 'Esta seguro?',
            html:
              `Se encontró el medicamento: <b>${data.data[0].ItemCode}` + ' - ' + `${data.data[0].Dscription})</b>  en el pedido Nro. <strong>${data.data[0].DocNum}</strong> ` + `<br>
                  ¿Desea agregar este medicamento?`,
            showConfirmButton: true,
            showCancelButton: true
          }).then(({ value }) => {
            if (value) {
              const maximo = dataRecepcion.value.reduce((max, obj) => parseInt(obj.LineNum) > max ? parseInt(obj.LineNum) : max, dataRecepcion.value[0].LineNum) + 1
              data.data[0].LineNum = maximo
              data.data[0].distribucion = []
              recepcionPedidos.commit('onAddMx', data.data[0])
              if (!encabezado.value.pedidos.includes(data.data[0].DocNum)) encabezado.value.pedidos.push(data.data[0].DocNum)
              CodeBars.value = null
            }
          })
          return
        }
        if (data.data.length && data.medAdicional) {
          Swal.fire({
            icon: 'info',
            title: 'Esta seguro?',
            html:
              `Se encontró el medicamento: <b>${data.data[0].ItemCode}` + ' - ' + `${data.data[0].Dscription})</b> que no pertenece a ningún pedido inicial. ` + `<br>
                  ¿Desea agregar este medicamento?`,
            showConfirmButton: true,
            showCancelButton: true
          }).then((resp) => {
            if (resp.isConfirmed) {
              const maximo = dataRecepcion.value.reduce((max, obj) => parseInt(obj.LineNum) > max ? parseInt(obj.LineNum) : max, dataRecepcion.value[0].LineNum) + 1
              const dataSave = {
                LineNum: maximo,
                DocNum: null,
                DocEntry: null,
                BaseLine: null,
                ItemCode: data.data[0].ItemCode,
                nCompleto: `${data.data[0].ItemCode} - ${data.data[0].Dscription}`,
                U_PHR_ItemCodSolicitado: data.data[0].ItemCode,
                Dscription: data.data[0].Dscription,
                U_PHR_AgrupCom: data.data[0].Agrupacion,
                WhsCode: data.data[0].U_PHR_WarehouseCodeOri,
                U_PHR_WarehouseCodeOri: null,
                Quantity: 0,
                Price: 0,
                LineTotal: 0,
                LineStatus: 'O',
                IVA: data.data[0].taxRate,
                TaxCode: data.data[0].TaxCode,
                Regulado: data.data[0].Regulado,
                Controlado: data.data[0].Controlado,
                ReqMarca: false,
                CodeBars: data.data[0].CodeBars,
                U_PHR_Conversion: data.data[0].U_PHR_Conversion,
                rangoTemperatura: data.data[0].rangoTemperatura,
                cadenaFrio: data.data[0].cadenaFrio,
                temperatura: data.data[0].temperatura,
                lotes: [],
                U_PHR_Quantity: 0,
                U_PHR_UnitPriceSolicitado: 0,
                onQuantity: 0,
                lineaConfirmada: false,
                medAdicional: true,
                receivedQuantity: 0,
                cantidadDefectuosa: 0,
                sustitutos: [],
                distribucion: []
              }
              recepcionPedidos.commit('onAddMx', dataSave)
              CodeBars.value = null
            }
          })
        } else if (params.codigoBarras) {
          Swal.fire({
            icon: 'question',
            title: 'Información',
            html:
              `No se encontro ningún medicamento asociado al código de barras: <strong>${codebars}</strong><br> ¿Desea asociarlo a un medicamento?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si, asignar',
            cancelButtonText: 'No, cancelar'
          }).then(({ value }) => {
            if (value) {
              CodeBars.value = null
              openModalAsignarCodigos(codebars)
            }
          })
        }
      })
    }
    const validateTemp = (evnt, { index }) => {
      if (evnt.target.value) {
      const valueTemp = parseInt(evnt.target.value.replace(/[.,a-zA-Z$]/g, ''))
        if (valueTemp > detailTemp.value[index].rangoTemperatura || valueTemp < 1) {
          Swal.fire({
            icon: 'info',
            text: 'El medicamento ingresado esta por fuera del rango de temperatura.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          })
        }
        detailTemp.value[index].temperatura = valueTemp
        recepcionPedidos.commit('onUpdateTemp', detailTemp.value[index])
      }
    }
    const MxSustituto = ({ data, index }) => {
      const find = detailTemp.value[index].sustitutos.find((x) => x.ItemCode === data.ItemCode)
      detailTemp.value[index].U_PHR_ItemSustituto = find !== undefined ? find.Dscription : null
      detailTemp.value[index].U_PHR_ConversionSustituto = find !== undefined ? find.U_PHR_Conversion : null
      if (storeDetailsLotes.value.length) {
        recepcionPedidos.commit('changeItemCodeDocumentLine', data)
      }
    }
    const openModalAsignarCodigos = (codBar) => {
      const filter = detailTemp.value.filter((x) => x.medAdicional === false)
      const mapeo = filter.map((x) => {
        return {
          ItemCode: x.ItemCode,
          nombreCompleto: x.ItemCode + ' - ' + x.Dscription
        }
      })
      const miSet = new Set(mapeo.map(JSON.stringify))
      const miArrayUnico = Array.from(miSet).map(JSON.parse)
      openModalCodBars.value.openModalCodeBars(codBar, miArrayUnico)
    }
    const deleteLine = ({ index, data }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro?',
        html: `¿Desea eliminar el medicamento <strong>${data.ItemCode} - ${data.Dscription}</strong> ?`,
        confirmButtonText: 'Si',
        showCancelButton: true,
        cancelButtonText: 'No, cancelar'
      }).then(({ value }) => {
        if (value) {
          detailTemp.value.splice(index, 1)
          encabezado.value.pedidos = [...new Set(detailTemp.value.filter(a => ![null, ''].includes(a.DocNum)).map(x => x.DocNum))]
          recepcionPedidos.commit('onDeleteDocumentLine', data.LineNum)
          recepcionPedidos.commit('onDeleteLotesMd', data.LineNum)
        }
      })
    }
    const backPage = () => {
      recepcionPedidos.commit('onReset')
      router.push({ name: 'pharmasan.compras.logistica.recepcion-pedidos.listar-pedidos' })
    }
    const getLoad = () => {
      const params = {
        pedidos: encabezado.value.pedidos,
        codigoBarras: CodeBars.value
      }
      _RecepcionPedidosService.value.getLinesMx(params).then(({ data }) => {
        recepcionPedidos.commit('onSetLinesMx', data)
        dataRecepcion.value = storeRecepcionPedidos.value.data
        detailTemp.value = storeRecepcionPedidos.value.data
      })
    }

    const onSetDistribucion = (payload) => {
      const index = detailTemp.value.findIndex((x) => `${x.LineNum}${x.DocEntry}${x.ItemCode}${x.DocNum}` === `${payload.linea.LineNum}${payload.linea.DocEntry}${payload.linea.ItemCode}${payload.linea.DocNum}`)
      detailTemp.value[index].distribucion = payload.datos // esta es la dstribución temporal que se utiliza mientras se edita y se guarda
      recepcionPedidos.commit('onSetDistribucionBod', payload)
    }
    const openDistribuciones = async ({ data }) => {
      if (data.receivedQuantity === 0) {
        await Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Esta línea no tiene cantidades recibidas, por favor ingréselas e inténtelo nuevamente.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (data.distribucion) {
        const payload = {
          dataMx: data,
          detallado: data.distribucion,
          bod: storeRecepcionPedidoBodegas.value,
          distriBod: true
        }
        openModalDistribuciones.value.openDistribucionesModal(payload)
      } else {
        const params = {
          LineNum: data.BaseLine,
          DocNum: data.DocNum
        }
        await _RecepcionPedidosService.value.getDistribucionBodega(params).then((resp) => {
          const arrayT = []
          if (data.cantidadDefectuosa > 0) {
            arrayT.push({
              id: null,
              WhsCode: process.env.VUE_APP_WHSCODE_RECEPCION_PEDIDOS,
              U_PHR_WarehouseCodeOri: null,
              WhsName: process.env.VUE_APP_WHSNAME_RECEPCION_PEDIDOS,
              Quantity: data.cantidadDefectuosa,
              TempQuantity: data.cantidadDefectuosa,
              QuantitySol: null,
              solicitudes: [],
              distribucionNueva: false,
              AbsEntry: process.env.VUE_APP_ABS_ENTRY_UBICACION_DEVOLUCIONES,
              nombreUbicacion: process.env.VUE_APP_NOMBRE_UBICACION_DEVOLUCIONES,
              distribucionDefectuosa: true
            })
          }
          resp.data.forEach((element) => {
            arrayT.push(element)
          })

          const payload = {
            dataMx: data,
            detallado: arrayT,
            bod: storeRecepcionPedidoBodegas.value,
            distriBod: true
          }
          openModalDistribuciones.value.openDistribucionesModal(payload)
        })
      }
    }
    const openModal = async ({ index, data }) => {
      if (data.receivedQuantity === 0) {
        await Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Esta línea no tiene cantidades recibidas, por favor ingréselas e inténtelo nuevamente.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      const params = {
        detalle: data,
        indice: index,
        permisoFecha: !verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.fecha-vencimiento-mayor'),
        permisoCarta: verificarPermiso('pharmasan.compras.logistica.recepcion-pedidos.listado-recepciones.carta-compromiso')
      }
      if (data.Quantity <= 0 && data.Price <= 0) {
        Swal.fire({
          icon: 'info',
          text: 'La cantidad recibida y el precio confirmado del medicamento debe ser mayor a 0.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      ModalLotes.value.openModalLotes(params)
    }
    const updateCodeBar = (model) => {
      detailTemp.value.forEach((element, index) => {
        if (element.ItemCode === model.ItemCode) {
          detailTemp.value[index].CodeBars = model.BarCode
        }
      })
    }
    const editLine = ({ index, data }) => {
      const params = {
        index: index,
        data: data
      }
      recepcionPedidos.commit('onEditLine', params)
    }
    const verificarPermiso = (permiso) => {
      const user = store.getters['auth/user']
      if (!user.admin) {
        const permissions = store.getters['auth/getPermissions']
        if (permissions.some(a => a === permiso)) return true
        return false
      }
      return true
    }
    const onSubmit = () => {
      if (!encabezado.value.numFactura) {
        numFact.value.focus()
        Swal.fire({
          icon: 'info',
          text: 'El número de factura es obligatorio.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (!storeDetailsLotes.value.length) {
        Swal.fire({
          icon: 'info',
          text: 'Debe confirmar al menos una linea del pedido, para ello asigne los lotes corre',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if (storeDetailsLotes.value.some((x) => x.cadenaFrio && !x.temperatura)) {
        Swal.fire({
          icon: 'info',
          text: 'Debe ingresar la temperatura en los medicamentos con cadena de frio, por favor revisar',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      const numPedidos = [...new Set(encabezado.value.pedidos.map((d) => d))]
      Swal.fire({
        icon: 'question',
        title: '¿ Esta seguro ? ',
        text: `Desea crear la recepción en base a los pedidos (${numPedidos.join(',')})`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, crear',
        cancelButtonText: 'No, cancelar'
      }).then(({ value }) => {
        if (value) {
          const pedido = {
            DocDate: encabezado.value.DocDate,
            DocDueDate: encabezado.value.ExpDate,
            TaxDate: encabezado.value.TaxDate,
            NumAtCard: encabezado.value.numFactura,
            Comments: 'Basado en pedidos ' + numPedidos,
            JournalMemo: 'Pedido de ' + encabezado.value.CardName,
            CardCode: encabezado.value.CardCode,
            CardName: encabezado.value.CardName,
            Area: 'BODEGA',
            json: '',
            numero_pedidos: '' + numPedidos,
            detalle: storeDetailsLotes.value
          }
          const validate = pedido.detalle.some((a) => detailTemp.value.find((x) => x.LineNum === a.LineNum && x.DocNum === a.BaseRef && x.WhsCode === a.WarehouseCode).Quantity !== a.lotes.reduce((acc, val) => acc + parseInt(val.Quantity), 0))
          if (!validate) {
            _RecepcionPedidosService.value.createRecepcion(pedido).then((response) => {
              responseError.value = response.data
              if (response.data.DocEntry) {
                Swal.fire({
                  icon: 'success',
                  title: 'Éxito',
                  text: 'Se registro la información correctamente',
                  showConfirmButton: true
                }).then((response) => {
                  if (response.isConfirmed) {
                    backPage()
                  }
                })
              }
            })
          } else {
            const validaErrors = pedido.detalle.filter((a) => detailTemp.value.find((x) => x.LineNum === a.LineNum && x.DocNum === a.BaseRef && x.WhsCode === a.WarehouseCode).Quantity !== a.lotes.reduce((acc, val) => acc + parseInt(val.Quantity), 0))
            let html = ''
            let detalle = ''
            if (validaErrors.length) {
              validaErrors.forEach(item => {
                detalle +=
                  `
                <tr>
                  <td align="left" width="70%">
                    <p style="font-size: 12px">${item.ItemCode} - ${item.ItemName}</p>
                  </td>
                  <td align="left" width="30%">
                    <p style="font-size: 12px">${item.BaseRef}</p>
                    </td>
                </tr>`
                html = `
              <div style="max-height: 300px;overflow: auto;">
              <table cellpadding="0" cellspacing="0" width="100%" border style="padding-top: 5px">
                  <tr>
                    <th align="center" width="50%">
                      <p style="font-size: 15px">Existen inconsistencias en las cantidades, por favor revisar los siguientes medicamentos:</p>
                    </th>
                  </tr>
                </table>
                <table cellpadding="0" cellspacing="0" width="100%" border style="padding-top: 5px">
                  <tr>
                    <th align="left" width="70%">
                     <p style="font-size: 15px"> Medicamento:</p>
                    </th>
                    <th align="left" width="30%">
                      <p style="font-size: 15px">Número de pedido:</p>
                    </th>
                  </tr>
                  <tr>${detalle}</tr>
                </table>
                <br>
              </div>`
              })
            } else {
              html = responseError.value.data.menssage
            }
            Swal.fire({
              icon: 'error',
              title: 'Advertencia',
              html: `${html}`,
              width: 600,
              showConfirmButton: true
            })
          }
        }
      })
    }
    const rowClass = (data) => {
      return data.lineaConfirmada === true ? 'gestionada' : null
    }
    const getBodegas = () => {
      _RecepcionPedidosService.value.getBodegas().then(({ data }) => {
        recepcionPedidos.commit('onSetBodegas', data)
      })
    }
    onMounted(async () => {
      recepcionPedidos.commit('onReset')
      encabezado.value = storeEncabezado.value
      if (encabezado.value.CardCode) {
        const numPedidos = [...new Set(encabezado.value.pedidos.map((d) => d))]
        JournalMemo.value = 'Pedido de ' + encabezado.value.CardName
        Comments.value = 'Pedido de entrada de mercancías ' + numPedidos
        await getLoad()
        await getBodegas()
      } else {
        router.push('recepcion-pedidos/listar-pedidos', { pageIndex: 1 })
      }
    })
    return {
      detailTemp,
      storeRecepcionPedidos,
      getLoad,
      dataRecepcion,
      encabezado,
      storeEncabezado,
      ModalLotes,
      openModal,
      onSubmit,
      storeDetailsLotes,
      JournalMemo,
      Comments,
      editLine,
      totalPedido,
      totalSelec,
      filters,
      medicamentosList,
      searchCodeBars,
      CodeBars,
      numFact,
      backPage,
      openModalAsignarCodigos,
      openModalCodBars,
      rowClass,
      responseError,
      updateCodeBar,
      deleteLine,
      MxSustituto,
      openModalDistribuciones,
      openDistribuciones,
      storeRecepcionPedidoBodegas,
      onSetDistribucion,
      validateTemp,
      totalSumIva
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.inputsEditableHeader {
  width: 100%;
  height: 100%;
}

.p-card.p-card-body {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.footerModal {
  display: flex;
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}

::v-deep(.p-inputnumber) {
  width: 2rem;
}

::v-deep(.p-tooltip) {
  width: 10rem;
}

.text-xs {
  font-size: 0.70rem;
}

::v-deep(.gestionada) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
:global(.swal2-container) {
  z-index: 1000000 !important
}
</style>
